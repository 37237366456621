export default defineNuxtPlugin((nuxtApp: any) => {
  const { hostname } = useRequestURL();
  const rootDomain = hostname.split(".").slice(-2).join(".");
  const config = useRuntimeConfig();
  nuxtApp.hook("app:mounted", () => {
    useHead({
      script: [
        config.public.websiteEnv === "production"
          ? {
              hid: "gtm",
              children: `(function (w, d, s, l) {
                w[l] = w[l] || [];
                w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js'
                });
                const f = d.getElementsByTagName(s)[0];
                const j = d.createElement(s);
                const dl = l !== 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://ss.${rootDomain}/webevents';
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer');`,
              type: "text/javascript",
              defer: true,
            }
          : {},
      ],
    });
  });
  // return {
  //   parallel: true,
  // };
});
