import { GraphQLClient } from "graphql-request";

export default defineNuxtPlugin(() => {
  const { magentoHeaders } = useVSFCookies();
  const requestUrl = useRequestURL();

  const client: GraphQLClient = new GraphQLClient(`${requestUrl.origin}/magento`, {
    headers: () => ({
      ...magentoHeaders(),
    }),
    method: `GET`,
    errorPolicy: "all",
    jsonSerializer: {
      parse: JSON.parse,
      stringify: JSON.stringify,
    },
  });

  return {
    parallel: true,
    provide: {
      graphql: {
        query: async <T>(query: any, queryVars?: any, customHeaders?: any) => {
          return client.rawRequest<T>(query, queryVars, customHeaders);
        },
        mutate: <T>(query: any, queryVars?: any, customHeaders?: any) => {
          return client.rawRequest<T>(query, queryVars, customHeaders);
        },
      },
    },
  };
});
