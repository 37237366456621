import TinyCustomerQuery from "./queries/tinyCustomerQuery.gql.js";
import GenerateCustomerTokenMutation from "./queries/generateCustomerToken.gql";
import CustomerQuery from "./queries/customer.gql";
import RovokeCustomerTokenMutation from "./queries/revokeCustomerToken.gql";
import UpdateCustomerMutation from "./queries/updateCustomer.gql";
import ChangeCustomerPasswordMutation from "./queries/changeCustomerPassword.gql";
import CreateCustomerMutation from "./queries/createCustomer.gql";
import GetCustomerAddressesQuery from "./queries/getCustomerAddresses.gql";
import UpdateCustomerAddressMutation from "./queries/updateCustomerAddress.gql";
import DeleteCustomerAddressMutation from "./queries/deleteCustomerAddress.gql";
import CreateCustomerAddressMutation from "./queries/createCustomerAddress.gql";
import UpdateCustomerEmailMutation from "./queries/updateCustomerEmail.gql";
import type {
  UseCustomerState,
  CustomerUpdateInput,
  CustomerAddressInput,
  CustomerCreateInput,
  GenericCustomerResponse,
  CustomerTokenResponse,
} from "./types";

/**
 * @description Composable managing customer data
 * @returns {@link UseCustomerReturn}
 * @example
 * const { data, loading, fetchCustomer } = useCustomer();
 */
export const useCustomer = () => {
  const { notifyError } = useNotifications();
  const { $graphql } = useNuxtApp();
  const { cookieOptions } = useCookieOptions();

  const customerState = useState<UseCustomerState>("useCustomerState", () => ({
    data: null,
    token: useCookie("vsf-customer", cookieOptions.value)?.value,
  }));

  const checkCustomerToken = async () => {
    try {
      const { data, errors } = await $graphql.query(TinyCustomerQuery);

      if (errors?.length) {
        customerState.value.token = null;
        useCookie("vsf-customer", cookieOptions.value).value = null;
      } else {
        customerState.value.token = useCookie("vsf-customer").value;
      }

      return {
        data: !errors,
      };
    } catch (error) {
      return {
        data: false,
        error,
      };
    }
  };

  const login = async ({ email, password }: { email: string; password: string }) => {
    const { fetchCustomerCart } = useCart();
    const { fetchWishlists } = useWishlists();
    const { data, errors } = await $graphql.mutate<CustomerTokenResponse>(GenerateCustomerTokenMutation, {
      email,
      password,
    });
    const customerToken = data.generateCustomerToken?.token;
    if (customerToken) {
      customerState.value.token = customerToken;
      useCookie("vsf-customer", cookieOptions.value).value = customerToken;
      fetchCustomerCart();
      fetchWishlists();
    }

    return {
      data: customerToken,
      errors: errors?.filter(Boolean),
    };
  };

  const fetchCustomer = async () => {
    const { data, status, error, refresh } = await useAsyncData(() => $graphql.query(CustomerQuery), {
      transform: (data: any) => ({ customer: data?.data?.customer, errors: data?.errors }),
    });

    // watch(data, () => {
    const errors = data.value?.errors;
    const customer = data.value?.customer;
    if (errors?.length) {
      customerState.value.token = null;
      useCookie("vsf-customer", cookieOptions.value).value = null;
      notifyError(errors[0].toString());
    } else if (customer) customerState.value.data = customer;
    // });

    return {
      data,
      status,
      error,
      refresh,
    };
  };

  const logout = async () => {
    const { deleteCart } = useCart();
    await $graphql.mutate(RovokeCustomerTokenMutation);
    customerState.value.token = null;
    customerState.value.data = null;
    useCookie("vsf-customer", cookieOptions.value).value = null;
    deleteCart();
  };

  const updateCustomer = async (customer: CustomerUpdateInput) => {
    const { data, errors } = await $graphql.mutate(UpdateCustomerMutation, { input: customer });

    if (errors) notifyError(errors[0]?.message);
    else if (data) customerState.value.data = data.updateCustomerV2?.customer;

    return {
      data,
    };
  };

  const updateCustomerEmail = async (email: string, password: string) => {
    const { data, status, error, refresh } = await useAsyncData(
      () => $graphql.mutate(UpdateCustomerEmailMutation, { email, password }),
      {
        transform: (data: any) => ({ data: data?.data?.updateCustomerEmail?.customer, errors: data?.errors }),
      },
    );
    if (data.value?.errors) notifyError(data.value.errors[0].message);
    else if (data.value?.data) customerState.value.data = data.value.data;

    return {
      data,
      status,
      error,
      refresh,
    };
  };

  const changeCustomerPassword = async (currentPassword: string, newPassword: string) => {
    const { data, errors } = await $graphql.mutate<GenericCustomerResponse<"changeCustomerPassword">>(
      ChangeCustomerPasswordMutation,
      { currentPassword, newPassword },
    );

    if (errors) notifyError(errors[0].message);

    return {
      data,
      errors,
    };
  };

  const register = async (customer: CustomerCreateInput) => {
    const { data, errors } = await $graphql.mutate<GenericCustomerResponse<"createCustomerV2">>(
      CreateCustomerMutation,
      { input: customer },
    );

    if (errors) notifyError(errors[0].message);
    if (data?.createCustomerV2?.customer) customerState.value.data = data.createCustomerV2.customer;

    return {
      data,
      errors,
    };
  };

  const fetchCustomerAddresses = async () => {
    const { data, errors } = await $graphql.query(GetCustomerAddressesQuery);
    if (errors) notifyError(errors[0].message);
    else if (data?.customer && customerState.value.data?.addresses)
      customerState.value.data.addresses = data.customer?.addresses;

    return {
      data,
      errors,
    };
  };

  const updateCustomerAddress = async (id: number, address: CustomerAddressInput) => {
    const { data, errors } = await $graphql.mutate<GenericCustomerResponse<"updateCustomerAddress">>(
      UpdateCustomerAddressMutation,
      { id, input: address },
    );

    if (errors) notifyError(errors[0].message);
    // else if (data.value?.data && customerState.value.data?.addresses) {
    //   customerState.value.data.addresses = customerState.value.data?.addresses?.map((address) =>
    //     address?.id === id ? (data.value?.data as CustomerAddress) : address,
    //   );
    // } TODO
    else {
      fetchCustomerAddresses();
    }

    return {
      data,
      errors,
    };
  };

  const addCustomerAddress = async (address: CustomerAddressInput) => {
    const { data, errors } = await $graphql.mutate<GenericCustomerResponse<"createCustomerAddress">>(
      CreateCustomerAddressMutation,
      { input: address },
    );

    if (errors) notifyError(errors[0].message);
    else {
      fetchCustomerAddresses();
    }

    return {
      data,
      errors,
    };
  };

  const removeCustomerAddress = async (id: number) => {
    const { data, errors } = await $graphql.mutate(DeleteCustomerAddressMutation, { id });
    if (errors) notifyError(errors[0].message);
    else {
      fetchCustomerAddresses();
    }
    return {
      data,
      errors,
    };
  };

  return {
    login,
    logout,
    fetchCustomer,
    fetchCustomerAddresses,
    updateCustomerAddress,
    removeCustomerAddress,
    updateCustomer,
    updateCustomerEmail,
    changeCustomerPassword,
    addCustomerAddress,
    checkCustomerToken,
    register,
    ...toRefs(customerState.value),
  };
};
