import type { Product } from "../useCategory/types";
import addProductsToCartMutation from "./queries/addProductsToCartMutation.gql";
import addSimpleProductsToCartMutation from "./queries/addSimpleProductsToCartMutation.gql";
import cartQuery from "./queries/cartQuery.gql";
import customerCartQuery from "./queries/customerCartQuery.gql";
import mergeCartsMutation from "./queries/mergeCartsMutation.gql";
import removeItemFromCartMutation from "./queries/removeItemFromCartMutation.gql";
import updateCartItemsMutation from "./queries/updateCartItemsMutation.gql";
import createEmptyCartQuery from "./queries/createEmptyCart.gql";
import type { UseCartState, Cart, Totals, Discount, GenericCartResponse } from "./types";

export const useCart = () => {
  const {
    $graphql,
    $i18n: { t: $t },
  } = useNuxtApp();
  const { handleError } = useErrorHandler();
  const { notify } = useNotifications();
  const { token: isAuthenticated } = useCustomer();
  const { addToCart: GAAddToCart, removeFromCart: GARemoveFromCart } = useAnalytics();
  const { cookieOptions } = useCookieOptions();

  const cookieCart = useCookie("vsf-cart", cookieOptions.value);

  const cartState = useState<UseCartState>("useCartState", () => ({
    data: {
      id: cookieCart.value,
    },
    loading: false,
    error: null,
    cookieReset: false,
  }));

  // only on first load (so SSR req), reset the cookie
  if (import.meta.client && !cartState.value.cookieReset && useRuntimeConfig().public.websiteEnv === "production") {
    useFetch("/cookieReset");

    watch(cookieCart, async () => {
      if (cookieCart.value && cookieCart.value !== cartState.value.data?.id) {
        cartState.value.data!.id = cookieCart.value;
        await fetchCart();
      }
    });
    cartState.value.cookieReset = true;
  }

  const persistNewCart = async (cart?: Cart) => {
    if (!cart) return;

    if (cookieCart.value && cookieCart.value !== cart.id) {
      // There is already a cart cookie, so we need to merge the carts
      const mergedCart = await $graphql.mutate(mergeCartsMutation, {
        sourceCartId: cookieCart.value,
        destinationCartId: cart.id as string,
      });

      if (mergedCart.data?.mergeCarts) {
        cartState.value.data = mergedCart.data?.mergeCarts as Cart;
        cookieCart.value = mergedCart.data?.mergeCarts.id;
      } else {
        cookieCart.value = cart.id;
        cartState.value.data = cart;
      }
    } else {
      cookieCart.value = cart.id;
      cartState.value.data = cart;
    }
  };

  const deleteCart = async () => {
    cookieCart.value = null;
    cartState.value.data = null;
    return await fetchCart();
  };

  const fetchCart = async () => {
    useLogger().info("Fetching cart");
    if (isAuthenticated.value) {
      return await fetchCustomerCart();
    }

    const cartId = cookieCart.value;
    if (cartId) {
      return await fetchCartById(cartId);
    }

    return await fetchEmptyCart();
  };

  const fetchCustomerCart = async () => {
    useLogger().info("Fetching customer cart");
    cartState.value.loading = true;

    const { data, errors } = await $graphql.query(customerCartQuery);

    if (errors) {
      await handleError(errors);
      return await fetchEmptyCart();
    } else {
      const customerCart = data?.customerCart;
      persistNewCart(customerCart);
    }
    cartState.value.loading = false;

    return {
      data,
    };
  };

  const fetchCartById = async (cartId: string) => {
    cartState.value.loading = true;
    useLogger().info("Fetching cart by id");

    const { data, errors } = await $graphql.query<any>(cartQuery, { cartId });

    if (errors) {
      notify($t("cart.noAccessToCart"));
      return await fetchEmptyCart();
    } else {
      const cart = data.cart;
      persistNewCart(cart as Cart);
    }
    cartState.value.loading = false;

    return {
      data,
    };
  };

  const fetchEmptyCart = async () => {
    useLogger().info("Fetching empty cart");
    cartState.value.loading = true;
    const { data, errors } = await $graphql.query<any>(createEmptyCartQuery);

    if (errors) {
      await handleError(errors);
    } else if (data.createEmptyCart) {
      const cartId = data.createEmptyCart as string;
      persistNewCart({ id: cartId } as Cart);
    }

    cartState.value.loading = false;

    return {
      data,
    };
  };

  const totalItems: ComputedRef<number> = computed(() => {
    return cartState.value.data?.items?.length ?? 0;
  });

  const items: ComputedRef<Cart["items"]> = computed(() => {
    return cartState.value.data?.items ?? [];
  });

  const totals: ComputedRef<Totals> = computed(() => {
    const cart = cartState.value.data;
    if (!cart || !cart.prices) return {} as Totals;
    const subtotal = cart.prices.subtotal_including_tax?.value ?? 0;

    return {
      total: cart.prices.grand_total?.value,
      subtotal,
      special: cart.prices.discounts ? subtotal - calculateDiscounts(cart.prices.discounts as Discount[]) : subtotal,
      shipping: cart.shipping_addresses?.[0]?.selected_shipping_method?.amount?.value,
      currency: cart.prices.grand_total?.currency,
    } as Totals;
  });

  const calculateDiscounts = (discounts: Discount[]): number =>
    discounts?.reduce((a, b) => Number.parseFloat(`${a}`) + Number.parseFloat(`${b.amount.value}`), 0);

  const discount: ComputedRef<number> = computed(() => {
    const cart = cartState.value.data;
    if (!cart || !cart.prices) return 0;
    return calculateDiscounts(cart.prices.discounts as Discount[]);
  });

  const addSingleItem = async (productSku: string, quantity: number, selectedOptions = []) => {
    const { data, errors } = await $graphql.mutate<GenericCartResponse<"addProductsToCart">>(
      addProductsToCartMutation,
      {
        cartId: cartState.value.data?.id as string,
        cartItems: [
          {
            quantity,
            sku: productSku,
            selected_options: selectedOptions,
          },
        ],
      },
    );

    if (errors) {
      await handleError(errors);
    } else if (data.addProductsToCart?.user_errors?.length) {
      handleError(data.addProductsToCart.user_errors);
    } else if (data?.addProductsToCart?.cart) {
      cartState.value.data = data.addProductsToCart?.cart;
      GAAddToCart(
        data.addProductsToCart?.cart?.items?.find((item: any) => item.product.sku === productSku)?.product as any,
        quantity,
      );
    }

    return {
      data,
      errors,
    };
  };

  const addSampleToCart = async (productSku: string) => {
    const { data, errors } = await $graphql.mutate<GenericCartResponse<"addSimpleProductsToCart">>(
      addSimpleProductsToCartMutation,
      {
        input: {
          cart_id: cartState.value.data?.id as string,
          cart_items: [
            {
              data: {
                quantity: 1,
                sku: productSku,
                stype: "sample",
              },
            },
          ],
        },
      },
    );

    if (errors) {
      await handleError(errors);
    } else if (data?.addSimpleProductsToCart?.cart) {
      cartState.value.data = data.addSimpleProductsToCart.cart;
      GAAddToCart(
        data.addSimpleProductsToCart.cart?.items?.find((item: any) => item.product.sku === productSku) as any,
        1,
      );
    }

    return {
      data,
      errors,
    };
  };

  const updateCart = async (cartItems: Array<{ cart_item_uid: string; quantity: number }>) => {
    const { data, errors } = await $graphql.mutate<GenericCartResponse<"updateCartItems">>(updateCartItemsMutation, {
      input: {
        cart_id: cartState.value.data?.id as string,
        cart_items: cartItems,
      },
    });

    if (errors) {
      await handleError(errors);
    } else if (data?.updateCartItems?.cart) {
      cartState.value.data = data.updateCartItems.cart;
    }

    return {
      data,
      errors,
    };
  };

  const removeItem = async (cartItemUid: string) => {
    const cartItem = cartState.value.data?.items?.find((item: any) => item.uid === cartItemUid);
    GARemoveFromCart(cartItem?.product as Product, cartItem?.quantity || 1);
    const { data, errors } = await $graphql.mutate<GenericCartResponse<"removeItemFromCart">>(
      removeItemFromCartMutation,
      {
        input: {
          cart_id: cartState.value.data?.id as string,
          cart_item_uid: cartItemUid,
        },
      },
    );

    if (errors) {
      await handleError(errors);
    } else if (data?.removeItemFromCart?.cart) {
      cartState.value.data = data.removeItemFromCart.cart;
    }

    return {
      data,
      errors,
    };
  };

  return {
    fetchCart,
    fetchCustomerCart,
    totalItems,
    totals,
    items,
    discount,
    addSingleItem,
    addSampleToCart,
    updateCart,
    removeItem,
    deleteCart,
    ...toRefs(cartState.value),
  };
};
