import type { CookieRef } from "nuxt/app";
import type { Product } from "../useCategory/types";

export enum GAEventType {
  ADD_TO_CART = "add_to_cart",
  ADD_TO_WISHLIST = "add_to_wishlist",
  REMOVE_FROM_CART = "remove_from_cart",
  REMOVE_FROM_WISHLIST = "remove_from_wishlist",
  SEARCH = "search",
  BEGIN_CHECKOUT = "begin_checkout",
  ADD_PAYMENT_INFO = "add_payment_info",
  ADD_SHIPPING_INFO = "add_shipping_info",
  ADD_BILLING_ADDRESS = "add_billing_address",
  PURCHASE = "purchase",
  VIEW_CART = "view_cart",
  LOGIN = "login",
  SIGNUP = "sign_up",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
}
export type GAItem = {
  item_id: string;
  item_name?: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
};
export type GAVariables = {
  currency?: string;
  value: number;
  items?: Array<GAItem>;
};
export interface UseAnalyticsInterface {
  addToCart: (product: Product, quantity: number) => void;
  addToWishlist: (product: Product) => void;
  removeFromCart: (product: Product, quantity: number) => void;
  removeFromWishlist: (product: Product) => void;
  search: (term: string) => void;
  beginCheckout: (products: Array<any>, value: number) => void;
  addShippingInfo: (shippingMethod: string) => void;
  addBillingAddress: () => void;
  addPaymentInfo: (paymentMethod: string) => void;
  purchase: (products: Array<any>, transactionId: string, value: number, shippingCosts: number) => void;
  viewCart: () => void;
  login: () => void;
  signup: () => void;
  pageView: (title: string) => void;
  viewItem: (product: Product) => void;
  viewItemList: (name: string, id: string, products: Array<Product>) => void;

  cookieConsent: CookieRef<string | null | undefined>;
  showBanner: ComputedRef<boolean>;
}
