import revive_payload_client_4sVQNw7RlN from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_H4uUvhyrU1 from "/builds/paracord/storefront/nuxt3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_KAumv2pUni from "/builds/paracord/storefront/nuxt3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import vuetify_client_hints_client_umMQneJmAK from "/builds/paracord/storefront/nuxt3/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.js";
import unhead_KgADcZ0jPj from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/paracord/storefront/nuxt3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/paracord/storefront/nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/paracord/storefront/nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import nuxt_plugin_6wEQMY3tee from "/builds/paracord/storefront/nuxt3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import visual_editing_client_QlcJCvThTJ from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/sanity/dist/runtime/plugins/visual-editing.client.mjs";
import titles_dw2T9lEw4h from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/builds/paracord/storefront/nuxt3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/builds/paracord/storefront/nuxt3/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/paracord/storefront/nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/paracord/storefront/nuxt3/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/builds/paracord/storefront/nuxt3/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_AUP22rrBAc from "/builds/paracord/storefront/nuxt3/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import chunkErrorHandler_rQJKjN65ZD from "/builds/paracord/storefront/nuxt3/plugins/chunkErrorHandler.ts";
import clarity_FoYTnyX1Oc from "/builds/paracord/storefront/nuxt3/plugins/clarity.ts";
import dompurify_qvTkTTURiH from "/builds/paracord/storefront/nuxt3/plugins/dompurify.ts";
import graphql_client_xGaS9bqfba from "/builds/paracord/storefront/nuxt3/plugins/graphql.client.ts";
import sentry_client_shVUlIjFLk from "/builds/paracord/storefront/nuxt3/plugins/sentry.client.ts";
import webevents_qbGgzATFWX from "/builds/paracord/storefront/nuxt3/plugins/webevents.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/builds/paracord/storefront/nuxt3/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_H4uUvhyrU1,
  vuetify_icons_KAumv2pUni,
  vuetify_client_hints_client_umMQneJmAK,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  nuxt_plugin_6wEQMY3tee,
  visual_editing_client_QlcJCvThTJ,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_AUP22rrBAc,
  chunkErrorHandler_rQJKjN65ZD,
  clarity_FoYTnyX1Oc,
  dompurify_qvTkTTURiH,
  graphql_client_xGaS9bqfba,
  sentry_client_shVUlIjFLk,
  webevents_qbGgzATFWX,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]