import customQueryAdd from "./queries/customQueryAdd.gql";
import customQueryDelete from "./queries/customQueryDelete.gql";
import customQueryRead from "./queries/customQueryRead.gql";

import type { UseWishlistsState } from "./types";

export const useWishlists = () => {
  const { notifyError } = useNotifications();
  const { addToWishlist: GAAddToWishlist, removeFromWishlist: GARemoveFromWishlist } = useAnalytics();
  const { $graphql } = useNuxtApp();

  const wishlistsState = useState<UseWishlistsState>("useWishlistsState", () => ({
    data: [],
  }));

  const fetchWishlists = async () => {
    const { data, status, error, refresh } = await useAsyncData("wishlists", () => $graphql.query(customQueryRead), {
      transform: (data: any) => ({ wishlists: data.data?.customer?.wishlists, errors: data?.errors }),
    });

    const errors = data.value?.errors;
    const wishlists = data.value?.wishlists;
    if (errors?.length) {
      notifyError(errors[0].message);
    } else if (wishlists) wishlistsState.value.data = wishlists;

    return {
      data,
      status,
      error,
      refresh,
    };
  };

  const addFavorite = async (productSku: string) => {
    const id = wishlistsState.value.data[0]?.id as string;
    const items = [{ sku: productSku, quantity: 1 }];
    const { data, status, error, refresh } = await useAsyncData(
      "addFavorite",
      async () => await $graphql.query(customQueryAdd, { id, items }),
      {
        transform: (data: any) => ({ wishlist: data.data?.addProductsToWishlist?.wishlist, errors: data?.errors }),
      },
    );

    const errors = data.value?.errors;
    const wishlist = data.value?.wishlist;
    if (errors?.length) {
      notifyError(errors[0].message);
    } else if (wishlist) {
      wishlistsState.value.data[0] = wishlist;
      GAAddToWishlist(wishlist.items_v2?.items.find((item: any) => item.product.sku === productSku)?.product as any);
    }

    return {
      data,
      status,
      error,
      refresh,
    };
  };

  const removeFavorite = async (wishlistItemId: string) => {
    GARemoveFromWishlist(
      wishlistsState.value.data[0]?.items_v2?.items.find((item: any) => item.id === wishlistItemId)?.product as any,
    );
    const id = wishlistsState.value.data[0]?.id as string;
    const { data, status, error, refresh } = await useAsyncData(
      "removeFavorite",
      async () => await $graphql.query(customQueryDelete, { id, items: wishlistItemId }),
      {
        transform: (data: any) => ({ wishlist: data.data?.removeProductsFromWishlist?.wishlist, errors: data?.errors }),
      },
    );

    const errors = data.value?.errors;
    const wishlist = data.value?.wishlist;
    if (errors?.length) {
      notifyError(errors[0].message);
    } else if (wishlist) wishlistsState.value.data[0] = wishlist;

    return {
      data,
      status,
      error,
      refresh,
    };
  };

  const removeFavoriteBySku = async (productSku: string) => {
    const wishlist = wishlistsState.value.data[0];
    if (!wishlist) return;
    const wishlistItem = wishlist?.items_v2?.items.find((item) => item?.product?.sku === productSku);
    if (!wishlistItem) return;
    await removeFavorite(wishlistItem.id);
  };

  const toggleFavorite = async (productSku: string) => {
    if (isFavorite(productSku)) {
      return await removeFavoriteBySku(productSku);
    } else {
      return await addFavorite(productSku);
    }
  };

  const isFavorite = (productSku: string) => {
    const wishlist = wishlistsState.value?.data?.[0];
    if (!wishlist) return false;
    return wishlist?.items_v2?.items.some((item) => item?.product?.sku === productSku);
  };

  return {
    fetchWishlists,
    addFavorite,
    removeFavorite,
    isFavorite,
    toggleFavorite,
    removeFavoriteBySku,
    ...toRefs(wishlistsState.value),
  };
};

export default useWishlists;
