import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  mutation mergeCarts($sourceCartId: String!, $destinationCartId: String!) {
    mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $destinationCartId) {
      ${cartFragment}
    }
  }
`;
