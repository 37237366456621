import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  query customerCart {
    customerCart {
      ${cartFragment}
    }
  }
`;
