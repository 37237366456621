export const useErrorHandler = () => {
  // graphql-authorization
  const { notifyError } = useNotifications();
  const { t: $t } = useNuxtApp().$i18n;

  const { toggleLoginModal } = useUiState();
  const { checkCustomerToken } = useCustomer();

  const handleError = async (errors: readonly Error[]) => {
    if (errors?.some((error: any) => (error.extensions?.category as string)?.includes("authorization"))) {
      const { data: isActive } = await checkCustomerToken();
      if (!isActive) {
        await notifyError($t("cart.authorizationError"));
        toggleLoginModal();
      }
    } else {
      await notifyError(errors[0].message);
    }
  };

  return {
    handleError,
  };
};
