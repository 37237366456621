import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  mutation addSimpleProductsToCart($input: AddSimpleProductsToCartInput) {
    addSimpleProductsToCart(input: $input) {
      cart {
        ${cartFragment}
      }
    }
  }
`;
