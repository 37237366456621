import type { CookieOptions } from "#app";
import { H3Event } from "h3";
import { toRef } from "vue";

export function useCookieOptions(event?: H3Event) {
  let i18n;
  if (typeof useNuxtApp !== "undefined") {
    i18n = useNuxtApp().$i18n;
  }
  const config = useRuntimeConfig(event);

  // remove protocol and port from domain
  let domain = i18n?.localeProperties.value.domain?.replace(/(^\w+:|^)\/\//, "").replace(/:\d+$/, "");
  // convert i18n domain to root domain for production cookies
  if (config.public.websiteEnv === "production") {
    domain = `.${domain?.split(".").slice(-2).join(".")}`;
  }

  const cookieOptions: CookieOptions & { readonly: false } = {
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
    maxAge: 60 * 60 * 24 * 365,
    readonly: false,
    domain,
  };

  return {
    cookieOptions: toRef(cookieOptions),
  };
}
