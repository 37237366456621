import type { UseNotificationsState } from "./types";

export const useNotifications = () => {
  const notificationsState = useState<UseNotificationsState>("useNotifications", () => ({
    errors: [],
    infos: [],
    warnings: [],
  }));

  const notify = (message: string) => {
    notificationsState.value.infos.push(message);
  };
  const notifyError = async (message: string) => {
    // resolve when the error is removed
    return new Promise((resolve) => {
      if (!notificationsState.value.errors.includes(message)) {
        notificationsState.value.errors.push(message);
      }
      checkForErrorRemoval(message, resolve);
    });
  };
  const notifyWarning = (message: string) => {
    notificationsState.value.warnings.push(message);
  };

  const removeError = (index: number) => {
    notificationsState.value.errors.splice(index, 1);
  };
  const removeWarning = (index: number) => {
    notificationsState.value.warnings.splice(index, 1);
  };
  const removeInfo = (index: number) => {
    notificationsState.value.infos.splice(index, 1);
  };

  const checkForErrorRemoval = (message: string, resolve: (...args: any) => void) => {
    const interval = setInterval(() => {
      if (!notificationsState.value.errors.includes(message)) {
        clearInterval(interval);
        resolve();
      }
    }, 200);
  };

  return {
    notify,
    notifyError,
    notifyWarning,
    removeError,
    removeWarning,
    removeInfo,
    ...toRefs(notificationsState.value),
  };
};
