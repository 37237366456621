// import { useAnalytics } from "../useAnalytics";

const state = reactive({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isSearchBarOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState() {
  const { viewCart: GAViewCart } = useAnalytics();
  const { token: isAuthenticated } = useCustomer();

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (!state.isCartSidebarOpen) {
      GAViewCart();
    }
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = async () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    // resolve when the modal is closed
    return new Promise((resolve) => {
      state.isLoginModalOpen = !state.isLoginModalOpen;
      const interval = setInterval(() => {
        if (!state.isLoginModalOpen) {
          clearInterval(interval);
          if (isAuthenticated.value) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      }, 200);
    });
  };

  const toggleSearchBar = () => {
    state.isSearchBarOpen = !state.isSearchBarOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    isMobileMenuOpen: computed({ get: () => state.isMobileMenuOpen, set: (value) => (state.isMobileMenuOpen = value) }),
    isCartSidebarOpen: computed({ get: () => state.isCartSidebarOpen, set: (value) => (state.isCartSidebarOpen = value) }),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isSearchBarOpen: computed(() => state.isSearchBarOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleSearchBar,
    loginModal: computed({
      get: () => state.isLoginModalOpen,
      set: (value) => {
        state.isLoginModalOpen = value;
      },
    }),
  };
}
