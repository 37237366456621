import { gql } from "graphql-request";
import wishlistFragment from "./wishlistFragment";

export default gql`
  mutation addProductsToWishlist($id: ID!, $items: [WishlistItemInput!]!) {
    addProductsToWishlist(wishlistId: $id, wishlistItems: $items) {
      wishlist {
        ...wishlistFragment
      }
    }
  }
  ${wishlistFragment}
`;
