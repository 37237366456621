import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId,: $cartId, cartItems,: $cartItems) {
      cart {
        ${cartFragment}
      }
      user_errors {
        code
        message
      }
    }
  }
`;
