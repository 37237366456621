export const domainRegex = /^https:\/\/(rc|www|test)\.paracord(shop){0,1}\.[a-z]{2,4}/g;

export default function stripDomain(url?: string): string {
  if (!url) return "";
  return url.replace(domainRegex, "");
}

export const fixImageUrl = (url: string): string => {

  if (!url) return "";

  if (url.startsWith("data:image") || url.startsWith("blob:")) {
    return url;
  }

  url = url.replace(domainRegex, "");

  // if the url starts with static folder, we grab the image and provide it our own
  if (/\/static/gi.test(url)) {
    // return only the regex match
    const match = url.match(/(?=.*)[^/]*.\w*$/gi);
    if (match) {
      return `/images/${match[0]}`;
    }
  }

  return url
}