import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  query cart($cartId: String!) {
    cart(cart_id: $cartId) {
      ${cartFragment}
    }
  }
`;
