export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig();
  nuxtApp.hook("app:mounted", () => {
    useHead({
      script: [
        config.public.websiteEnv === "production" && nuxtApp.$i18n.locale.value === "nl-NL"
          ? {
              hid: "clarity",
              children: `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "nucphwdc98");`,
              type: "text/javascript",
              defer: true,
            }
          : {},
      ],
    });
  });
});
