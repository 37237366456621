export default `
fragment wishlistFragment on Wishlist {
  id
  items_count
  items_v2 (
    pageSize: 99
    currentPage: 1
  ) {
    items {
      id
      product{
        sku
        uid
        name
        url_key
        thumbnail {
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
      }
    }
  }
}
`;
