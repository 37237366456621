import type { CookieOptions } from "#app";
import { getDefaultLocale, getDefaultStore, getDefaultCurrency } from "~/helpers/utils/cookies";

export function useVSFCookies() {
  const i18n = tryUseNuxtApp()?.$i18n;
  const config = useRuntimeConfig();
  const { cookieOptions } = useCookieOptions();
  const { token: isAuthenticated } = useCustomer();

  const cookieLocale = useCookie("vsf-locale", {
    ...cookieOptions.value,
    default: () => getDefaultLocale(i18n),
  });
  const cookieStore = useCookie("vsf-store", {
    ...cookieOptions.value,
    default: () => getDefaultStore(i18n),
  });
  const cookieCurrency = useCookie("vsf-currency", {
    ...cookieOptions.value,
    default: () => getDefaultCurrency(i18n),
  });
  const cookieCustomerToken = useCookie("vsf-customer", {
    ...cookieOptions.value,
  });

  const customerToken = computed(() => isAuthenticated.value ?? cookieCustomerToken.value);

  const magentoHeaders = () => ({
    locale: cookieLocale.value ?? getDefaultLocale(i18n),
    store: cookieStore.value ?? getDefaultStore(i18n),
    "Content-Currency": cookieCurrency.value ?? getDefaultCurrency(i18n),
    ...(customerToken.value && customerToken.value !== "null" && { Authorization: `Bearer ${customerToken.value}` }),
  });

  return {
    cookieLocale,
    cookieStore,
    cookieCurrency,
    magentoHeaders,
  };
}
