import { gql } from "graphql-request";
import wishlistFragment from "./wishlistFragment";

export default gql`
  query customer {
    customer {
      wishlists {
        ...wishlistFragment
      }
    }
  }
  ${wishlistFragment}
`;
