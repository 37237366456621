import type { Product } from "../useCategory/types";
import type { CartItemInterface } from "../useCart/types";
import { GAEventType, type GAItem, type UseAnalyticsInterface } from "./types";

export const useAnalytics = (): UseAnalyticsInterface => {
  const { cookieOptions } = useCookieOptions();
  const cookieConsent = useCookie("cookies_consent", cookieOptions.value);

  const showBanner = computed(() => {
    return cookieConsent.value === undefined;
  });

  function event(...args: any) {
    // @ts-ignore
    if (!window?.dataLayer) {
      useLogger().info("GA4 event", args);
    }
    // @ts-ignore
    window?.dataLayer?.push({ event: args[0], ...args[1] });
  }

  /* convert VSF product to GA item */
  const VSFProductToGAItem = (product: Product): GAItem => {
    const categories = product?.categories
      ?.sort((category) => category?.level ?? 1000)
      ?.map((category) => category?.name)
      ?.reverse();
    return {
      item_id: product.sku!,
      item_name: product.name!,
      item_category: categories?.[0],
      item_category2: categories?.[1],
      item_category3: categories?.[2],
      item_category4: categories?.[3],
      item_category5: categories?.[4],
      item_variant: `${product.color}`,
      item_brand: `Paracord`,
    };
  };

  /* add to cart */
  const addToCart = (product: Product, quantity: number): void => {
    const currency = product?.price_range?.minimum_price?.final_price?.currency;
    const price = product?.price_range?.minimum_price?.final_price?.value;
    event(GAEventType.ADD_TO_CART, {
      currency,
      value: price,
      items: [{ ...VSFProductToGAItem(product), price, quantity }],
    });
  };

  /* add to wishlist */
  const addToWishlist = (product: Product): void => {
    const currency = product.price_range?.minimum_price?.final_price?.currency;
    const price = product.price_range?.minimum_price?.final_price?.value;
    event(GAEventType.ADD_TO_WISHLIST, {
      currency,
      value: price,
      items: [{ ...VSFProductToGAItem(product), price, quantity: 1 }],
    });
  };

  /* remove from cart */
  const removeFromCart = (product: Product, quantity: number): void => {
    const currency = product.price_range?.minimum_price?.final_price?.currency;
    const price = product.price_range?.minimum_price?.final_price?.value;
    event(GAEventType.REMOVE_FROM_CART, {
      currency,
      value: price,
      items: [{ ...VSFProductToGAItem(product), price, quantity }],
    });
  };

  /* remove from wishlist */
  const removeFromWishlist = (product: Product): void => {
    const currency = product?.price_range?.minimum_price?.final_price?.currency;
    const price = product?.price_range?.minimum_price?.final_price?.value;
    event(GAEventType.REMOVE_FROM_WISHLIST, {
      currency,
      value: price,
      items: [{ ...VSFProductToGAItem(product), price, quantity: 1 }],
    });
  };

  /* search */
  const search = (term: string): void => {
    event(GAEventType.SEARCH, { search_term: term });
  };

  /* Begin checkout */
  const beginCheckout = (products: Array<CartItemInterface>, value: number): void => {
    const items = products.map(({ product, quantity, prices }) => ({
      ...VSFProductToGAItem(product as Product),
      quantity,
      price: prices?.row_total?.value,
    }));
    const currency = products?.[0]?.product?.price_range?.minimum_price?.final_price?.currency;
    event(GAEventType.BEGIN_CHECKOUT, { currency, value, items });
  };

  /* add shipping info */
  const addShippingInfo = (shippingMethod: string): void => {
    event(GAEventType.ADD_SHIPPING_INFO, { shipping_tier: shippingMethod });
  };

  /* add billing address */
  const addBillingAddress = (): void => {
    event(GAEventType.ADD_BILLING_ADDRESS);
  };

  /* add payment info */
  const addPaymentInfo = (paymentMethod: string): void => {
    event(GAEventType.ADD_PAYMENT_INFO, { payment_type: paymentMethod });
  };

  /* purchase */
  const purchase = (products: Array<any>, transactionId: string, value: number, shippingCosts: number): void => {
    const items = products.map(({ product, quantity, prices }) => ({
      ...VSFProductToGAItem(product),
      quantity,
      price: prices?.row_total?.value,
    }));
    const currency = products[0].product.price_range?.minimum_price?.final_price?.currency;
    event(GAEventType.PURCHASE, { currency, value, items, transaction_id: transactionId, shipping: shippingCosts });
  };

  /* view cart */
  const viewCart = (): void => {
    event(GAEventType.VIEW_CART);
  };

  /* login */
  const login = (): void => {
    event(GAEventType.LOGIN);
  };

  /* signup */
  const signup = (): void => {
    event(GAEventType.SIGNUP);
  };

  const pageView = (title: string): void => {
    event({
      page_title: title,
    });
  };

  const viewItem = (product: Product): void => {
    const currency = product?.price_range?.minimum_price?.final_price?.currency;
    const value = product?.price_range?.minimum_price?.final_price?.value;
    event(GAEventType.VIEW_ITEM, { currency, value, items: [VSFProductToGAItem(product)] });
  };

  const viewItemList = (name: string, id: string, products: Array<Product>): void => {
    const items = products.map((product) => VSFProductToGAItem(product));
    event(GAEventType.VIEW_ITEM_LIST, { item_list_id: id, item_list_name: name, items });
  };

  return {
    cookieConsent,
    showBanner,
    addToCart,
    addToWishlist,
    removeFromCart,
    removeFromWishlist,
    search,
    beginCheckout,
    addShippingInfo,
    addBillingAddress,
    addPaymentInfo,
    purchase,
    viewCart,
    login,
    signup,
    pageView,
    viewItem,
    viewItemList,
  };
};
