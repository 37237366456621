import { gql } from "graphql-request";
import { cartFragment } from "./cartFragment";

export default gql`
  mutation removeItemFromCart($input: RemoveItemFromCartInput) {
    removeItemFromCart(input: $input) {
      cart {
        ${cartFragment}
      }
    }
  }
`;
