<template>
  <NuxtLayout>
    <pc-section class="error">
      <pc-heading>Oops!</pc-heading>
      <template v-if="error?.statusCode === 404">
        <pc-heading :level="2">Sorry, we couldn't find the page you were looking for.</pc-heading>
      </template>
      <template v-else>
        <h2>{{ error?.statusCode }}</h2>
        <p>
          <strong>{{ error?.message }}</strong>
        </p>
        <p>It looks like something broke.</p>
        <p>Sorry about that.</p>
        <!-- Show stacktrace in development mode. -->
        <pre v-if="$config.public.websiteEnv !== 'production'">{{ JSON.stringify(error) }}</pre>
      </template>
      <p class="go-back">
        Go back to our
        <pc-link link="/" @click="clearError"> homepage. </pc-link>
      </p>
    </pc-section>
  </NuxtLayout>
</template>

<script setup>
const error = useError();
</script>

<style lang="scss" scoped>
.error {
  background-image: url("/images/error_500.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;
  text-align: center;
  h1 {
    font-size: 4rem;
    margin: 0;
  }
  h2 {
    margin: var(--spacing-sm);
  }
  p.go-back {
    margin-top: 230px;
  }
  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    color: var(--c-text);
    background-color: var(--c-light);
    border-radius: 6px;
    word-wrap: normal;
    text-align: left;
    white-space: pre-wrap;
  }
}
</style>
