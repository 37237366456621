import type { CookieOptions } from "#app";

export const getDefaultLocale = (i18n?: any) => {
  return i18n?.localeProperties?.value?.name ?? i18n?.fallbackLocale?.value?.replace("-", "_") ?? "en_US";
};
export const getDefaultStore = (i18n?: any) => {
  return getDefaultLocale(i18n);
};
export const getDefaultCurrency = (i18n?: any) => {
  return i18n?.localeProperties?.value?.defaultCurrency ?? "USD";
};
