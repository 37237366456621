import { gql } from "graphql-request";
import wishlistFragment from "./wishlistFragment";

export default gql`
  mutation removeProductsFromWishlist($id: ID!, $items: [ID!]!) {
    removeProductsFromWishlist(wishlistId: $id, wishlistItemsIds: $items) {
      wishlist {
        ...wishlistFragment
      }
    }
  }
  ${wishlistFragment}
`;
