import sanitizeHtml, { defaults } from "sanitize-html";
import { domainRegex, fixImageUrl } from "~/helpers/image/stripDomain";
export default defineNuxtPlugin((nuxtApp) => {
  const options = {
    allowedTags: defaults.allowedTags.concat(["iframe", "img", "style", "script", "button"]),
    allowVulnerableTags: true,
    allowedAttributes: {
      ...defaults.allowedAttributes,
      iframe: ["allow", "allowfullscreen", "frameborder", "scrolling", "src", "width", "height", "style"],
      img: ["src", "alt", "width", "height"],
      div: ["class", "style", "id"],
      button: ["class", "data-*"],
    },
    allowedIframeHostnames: ["www.youtube.com", "player.vimeo.com", "form.jotform.com"],
    transformTags: {
      a: function (_tagName: string, attributes: any) {
        return {
          tagName: "a",
          attribs: {
            ...attributes,
            href: attributes.href.replace(domainRegex, ""),
          },
        };
      },
      img: function (_tagName: string, attributes: any) {
        return {
          tagName: "img",
          attribs: {
            ...attributes,
            src: fixImageUrl(attributes.src),
            loading: "lazy",
          },
        };
      },
    },
    exclusiveFilter: function (frame: any) {
      return frame.tag === "script" && frame.text?.indexOf("flowbox") === -1;
    },
  };

  nuxtApp.vueApp.directive("safe-html", {
    getSSRProps(binding) {
      return {
        innerHTML: sanitizeHtml(binding.value, options),
      };
    },
    created(el, binding) {
      const newHTML = sanitizeHtml(binding.value, options);
      if (el.innerHTML === newHTML) return;
      el.innerHTML = newHTML;
    },
    updated(el, binding) {
      const newHTML = sanitizeHtml(binding.value, options);
      if (el.innerHTML === newHTML) return;
      el.innerHTML = newHTML;
    },

    deep: true,
  });

  // return {
  //   parallel: true,
  //   // provide: {
  //   //   dompurify: {
  //   //     sanitize: (html: any) => sanitizeHtml(html, options),
  //   //   },
  //   // },
  // };
});
